.history-desc {
  color: #fff;
  font-family: PingFang SC;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 170%; /* 20.4px */
  width: 100%;
  text-align: left;
  margin-bottom: 10px;
  margin-top: 16px;
}
.img-list {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.img {
  width: 80px;
  height: 80px;
  background: #e0aa3b;
  margin-right: 12px;
  margin-bottom: 12px;
}
.img:nth-child(4n) {
  margin-right: 0;
}
.task-schedule {
  width: 100%;
  padding: 15px 20px;
  gap: 50px;
  border-radius: 5px;
  background: #fff;
}
.task-schedule-name {
  color: #000;
  font-family: PingFang SC;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}
.task-schedule-desc {
  color: rgba(0, 0, 0, 0.4);
  font-family: PingFang SC;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
}

.history-item {
  position: fixed;
  width: 360px;
  height: 442px;
  top: 150px;
  display: flex;
  flex-direction: column;
}
.history-item-title {
  width: 360px;
  height: 41px;
  flex-shrink: 0;
  border-radius: 20px 20px 0px 0px;
  border-top: 1px solid rgba(0, 0, 0, 0.10);
  background: #FFF;
  text-align: center;
  color: #000;
  font-family: PingFang SC;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height:40px; /* 27.2px */
}
.history-item-img {
  flex: 1;
}
.history-item-close {
  width: 360px;
  height: 41px;
  flex-shrink: 0;
  border-radius: 0px 0px 20px 20px;
  background: #9089FC;
  color: #FFF;
  font-family: PingFang SC;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  text-align: center;
  line-height: 40px; /* 27.2px */
}
