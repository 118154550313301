.user-content {
  width: 100%;
  border-radius: 5px;
  border: 1px solid rgba(144, 137, 252, 0.30);
  background: rgba(144, 137, 252, 0.10);
}
.avatar {
  width: 50px;
  height: 50px;
  border-radius: 10px;
  background: #9089fc;
}
.username {
  color: #FFF;
  font-family: PingFang SC;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.userdesc {
  color: rgba(255, 255, 255, 0.80);
  font-family: PingFang SC;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.surplus {
  color: #FFB13D;
  font-family: YouSheBiaoTiHei;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.goodsCard {
  border-radius: 10px;
  background: rgba(144, 137, 252, 0.30);
  box-sizing: content-box;
}
.selected {
  border: 2px solid #9089FC;
}
.goods-count {
  color: #FFF;
  font-family: PingFang SC;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.goods-price {
  color: #FFB13D;
  font-family: PingFang SC;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.goods-origin {
  color: rgba(255, 255, 255, 0.35);
  font-family: PingFang SC;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: line-through;;
}
.goods-list {
  display: flex;
  width: 100%;
  overflow: scroll;
}
.goods-list::-webkit-scrollbar {
  display: none;
}

.goods-btn {
  width: 100%;
  display: flex;
  padding: 15px 0px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  background: linear-gradient(115deg, #F7C96B 16.21%, #E0AA3B 83.13%);
  color: #FFF;
  font-family: PingFang SC;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}